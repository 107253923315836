import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-skillbar',
    templateUrl: './skillbar.component.html',
    styleUrls: ['./skillbar.component.scss']
})
export class SkillbarComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
